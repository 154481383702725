@font-face {
  font-family: OptumSansRegular;
  src: url(./fonts/OptumSans-Regular.otf);
}

@font-face {
  font-family: OptumSansBold;
  src: url(./fonts/OptumSans-Bold.otf);
}

#root {
  height: 100%;
  width: 100%;
  font-family: OptumSansRegular;
  position: absolute;
  top:0;
  left:0;
}


.perksHeader {
  font-size: 34px;
  margin-left: 80px;
  color: #002677;
  margin-bottom: 16px;
  margin-top: 24px;
}

.perksHeader2 {
  font-size: 32px;
  margin-left: 40px;
  color: #002677;
  margin-bottom: 16px;
  margin-top: 24px;
}

.addButtonText {
  padding-left: 5px;
  font-size: 16px;
  font-family: OptumSansBold;
}

.disp-header-muiTable {
  padding-bottom: 30px;
}

.loader-container-fullpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8ad;
}
.loader-fullpage {
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}
/* gear button style */
.p-button.p-button-secondary.p-button-gear {
  margin: 10px 0px;
  border: none;
  color: #316bbe;
}
.material-icons span {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.p-button.p-button-gear.material-icons {
  background: none !important;
}

.home3 {
  text-decoration: none;

  color: black;

  font-size: 25px;

  width: 95%;

  height: 100%;

  /* display: flex; */

  align-items: left;

  padding: 0 18px;

  border-radius: 4px;
}

.home4 {
  text-decoration: none;

  color: black;

  font-size: 22px;

  width: 95%;

  height: 100%;

  /* display: flex; */

  /* font-family: serif; */

  align-items: left;

  padding: 0 18px;

  border-radius: 4px;
  
}

.home1 {
  text-decoration: none;

  color: black;

  font-size: 18px;

  width: 90%;

  height: 100%;

  /* display: flex; */

  font-family: OptumSansRegular;

  align-items: left;

  padding-left: 80px;
  
  border-radius: 4px;
}

.home2 {

  display: inline-block;
  text-decoration: none;

  color:#002677;

  font-size: 15px;

  font-family: OptumSansRegular;

  width: 180px;

  height: 30px;

  /* display: flex; */

  align-items: left;

  font-weight: bold;

  transition: ease-out;

  padding: 0 20px 0 20px;
  
  padding-top:10px;

  border-radius: 1px;

}

.dialog-label {

}

.dialog-label-value {  
  font-weight: bold;
  display: flex-inline;
  margin-left:95px;
}

.label1{
  color:black;
  font-size: 20px;
  font-family: OptumSansRegular;
}
